.system-requirements h3 {
    font-size: 1.75rem;
    font-weight: 400;
    padding: 5px 0 15px;
    margin: 0;
    color: #3c3533;
    font-family: "Open Sans", sans-serif;
}

.system-requirements p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.system-requirements a {
    color: #43402f;
    text-decoration: none;
    border-bottom: 1px solid #b6b196;
}

.system-requirements a:hover {
    text-decoration: none;
    border-bottom: 1px solid #43402f;
    color: #43402f
}

.system-requirements ul li {
    list-style-type: none;
}

.system-requirements ul li ul li,
.system-requirements .feature-list li {
    list-style-type: circle;
}