.support-toolbox h3 {
    font-size: 1.75rem;
    font-weight: 400;
    padding: 5px 0 15px;
    margin: 0;
    color: #3c3533;
    font-family: "Open Sans",sans-serif;
}

.support-toolbox ul{
    margin-top: 0;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.support-toolbox a {
    color: #43402f;
    text-decoration: none;
    border-bottom: 1px solid #b6b196;
}

.support-toolbox a:hover {
    text-decoration: none;
    border-bottom: 1px solid #43402f;
    color: #43402f
}