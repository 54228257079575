.notes-list h4 {
    font-weight: 400;
    padding: 5px 0 15px;
    margin: 0;
    color: #3c3533;
    font-family: "Open Sans",sans-serif;
}

.notes-list a {
    color: #43402f;
    text-decoration: none;
    border-bottom: 1px solid #b6b196;
    text-transform: capitalize;
}

.notes-list a:hover {
    text-decoration: none;
    border-bottom: 1px solid #43402f;
    color: #43402f
}