.service-portal-home h1 {
    line-height: 80px;
    font-size: 65px;
    font-weight: 400;
    padding: 5px 0 15px;
    margin: 0;
    color: #3c3533;
    font-family: "Open Sans",sans-serif;
}

.service-portal-home h2 {
    font-weight: 400;
    padding: 5px 0 15px;
    margin: 0;
    color: #3c3533;
    font-family: "Open Sans",sans-serif;
    font-size: 2rem;
}

.service-portal-home a {
    color: #43402f;
    text-decoration: none;
    border-bottom: 1px solid #b6b196;
}

.service-portal-home a:hover {
    text-decoration: none;
    border-bottom: 1px solid #43402f;
    color: #43402f
}