.g3-update {
    color: #383c3b;
    font: 400 16px/26px 'din-2014', Helvetica, Arial, sans-serif;
    max-width: 1170px;
    box-sizing: border-box;
    margin: 0 auto;
    height: 100vh;
    background-color: #f0efef;
    position: relative;
}

.update-container {
    position: relative;
}

.update-container img {
    width: 100%;
    display: block;
    vertical-align: middle;
    border: 0;
    margin: 0.25em;
}

.update-copy {
    position: absolute;
    width: 100%;
    top: 80%;
}

.update-header {
    position: relative;
    padding: 32px;
}

.g3-update h3,
.g3-update h4,
.g3-update h5,
.g3-update p {
    margin: 0;
    padding: 0;
}

.g3-update h3 {
    font: italic 300 65px/73px 'factoria', Georgia, serif;
    color: #000;
}

.g3-update h4 {
    font: italic 700 28px/1 'factoria', Georgia, serif;
}

.g3-update h5 {
    font: italic 700 24px/1 'factoria', Georgia, serif;
}

.g3-update a {
    font: 700 16px/1.6 'din-2014', Helvetica, Arial, sans-serif;
    text-decoration: none;
    color: #c00;
    border: none;
}

.update-header h3,
.update-header p {
    position: relative;
    z-index: 1;
}

.update-header h3 {
    max-width: 85%;
}

.update-header p,
.update-header a {
    font-size: 24px;
    line-height: 1.6;
}

.update-header p {
    max-width: 67%;
}

.update-header a {
    word-wrap: break-word;
}

.update-header::after {
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, .85);
    width: 100%;
    height: 100%;
    top: 0;
    left: -15%;
    transform: skewX(-17deg);
}

.update-body {
    background-color: #f0efef;
}

.update-body__main {
    padding: 28px 40px 40px;
}

.dealer-info {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 64px;
    background-color: #f0efef;
}

.dealer-instruction {
    flex-basis: 50%;
    margin: 0 20px;
    background-color: #fff;
    position: relative;
}

.dealer-instruction__title {
    padding: 16px;
    position: absolute;
    left: -36px;
    top: -16px;
    display: inline-block;
}

.dealer-instruction__title::after {
    content: '';
    position: absolute;
    background-color: #c00;
    height: 100%;
    width: 100%;
    top: 0;
    transform: skewX(-17deg);
}

.dealer-instruction__title h5 {
    color: #fff;
    position: relative;
    padding-left: 28px;
    z-index: 1;
}

.dealer-instruction__copy {
    padding: 56px 32px 24px;
}

.dealer-instruction p,
.dealer-instruction li,
.component-list li {
    margin-bottom: 16px;
}

.dealer-instruction ul,
.component-list ul {
    margin: 0;
    list-style-type: none;
    padding-left: 16px;
}

.dealer-instruction li,
.component-list li {
    line-height: 1.4;
}

.dealer-instruction li>span,
.component-list li>span {
    display: block;
    padding-left: 16px;
}