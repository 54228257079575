/* .document-list-item .hover-expand {
    display: none;
}

.document-list-item:hover .hover-expand {
    display: block;
    position: fixed;
    top: 10vh;
    max-height: 60vh;
    z-index:999;
} */

.document-list-item:hover {
    transform: scale(1.125);
    z-index:99;
    /*transform translate for edge items*/
}