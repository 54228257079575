.g3-components ul {
    margin: 0px;
    list-style-type: none;
    padding-left: 16px;
}

.g3-components ul li {
    line-height: 1.4;
    margin-bottom: 16px;
}

.g3-components ul li span {
    display: block;
    padding-left: 16px;
}

.g3-components ul li span img {
    max-height: 100px;
}